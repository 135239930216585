import React from "react"
import Table from "react-bootstrap/Table"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Statistics = () => (
  <Layout pageInfo={{ pageName: "Statistics" }}>
    <SEO title="Statistics" />
    <h2>
      <strong>Year of establishment</strong>
    </h2>
    <p>
      Dept was established in the year <code>1997</code>{" "}
      <code>
        12 (B) of UGC act vide Letter No.- 9-3 (929 CPP-1) dated 19th cot- 2004
      </code>{" "}
      and followed by B.N. Mandal University notification memo{" "}
      <code>No-GS-1166/04 dated 31st Oct 2004</code>.
    </p>
    <h2>
      <strong>Number of Teachers Sanctioned and Present position.</strong>
    </h2>
    <p>
      (A) Post Sanctioned – Post Sanctioned by Bihar Govt. vide letter No.{" "}
      <code>15/G 1-08/98-210 dated 31-01-2000</code>.
    </p>
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Professor</th>
          <th>Reader (Associate Prof.)</th>
          <th>Lecturer</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>01</td>
          <td>02</td>
          <td>04</td>
        </tr>
      </tbody>
    </Table>
    <p>
      (B) Present Position of Teachers- Promotion of all teachers are under
      CAS/MPS/TBP &amp; are personal promotion.
    </p>
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Professor</th>
          <th>Associate Prof.</th>
          <th>Assistant Prof.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>03</td>
          <td>03</td>
          <td>00</td>
        </tr>
      </tbody>
    </Table>
    <h2>
      <strong>Numbers of books in the Deptt. library</strong>
    </h2>
    <blockquote>
      <p>
        University Dept. of Chemistry has 178 Text Books &amp; 59 Reference
        Books till date about 70% of books are purchased in last tenth plan
        period from Univ. Grant Commission. Due to non availability of library
        space and non teaching staff in the department most of the books are
        temporarily shifted to central library of the University for Convenience
        and usefulness of the students as well as teachers. Where more than
        thousand books are available.
      </p>
    </blockquote>
    <h2>
      <strong>Success rate of Students in M.Sc. Final</strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>Years</p>
          </td>
          <td align="left">
            <p>Admitted</p>
          </td>
          <td align="left">
            <p>Appeared</p>
          </td>
          <td align="left">
            <p>Passed</p>
          </td>
          <td align="left">
            <p>Rate</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2009</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>100%</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2010</p>
          </td>
          <td align="left">
            <p>14</p>
          </td>
          <td align="left">
            <p>14</p>
          </td>
          <td align="left">
            <p>13</p>
          </td>
          <td align="left">
            <p>93%</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2011</p>
          </td>
          <td align="left">
            <p>09</p>
          </td>
          <td align="left">
            <p>09</p>
          </td>
          <td align="left">
            <p>08</p>
          </td>
          <td align="left">
            <p>89%</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2012</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>20</p>
          </td>
          <td align="left">
            <p>100%</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2013</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>20</p>
          </td>
          <td align="left">
            <p>19</p>
          </td>
          <td align="left">
            <p>
              <strong> </strong>
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
    <hr />
    <h2>
      <strong>Distinction/Ranks</strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>Years</p>
          </td>
          <td align="left">
            <p>Admitted</p>
          </td>
          <td align="left">
            <p>Appeared</p>
          </td>
          <td align="left">
            <p>Passed</p>
          </td>
          <td align="left">
            <p>Distinction/Ranks</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2009</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>04</p>
          </td>
          <td align="left">
            <p>1</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2010</p>
          </td>
          <td align="left">
            <p>14</p>
          </td>
          <td align="left">
            <p>14</p>
          </td>
          <td align="left">
            <p>13</p>
          </td>
          <td align="left">
            <p>Topper</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2011</p>
          </td>
          <td align="left">
            <p>09</p>
          </td>
          <td align="left">
            <p>09</p>
          </td>
          <td align="left">
            <p>08</p>
          </td>
          <td align="left">
            <p>Topper</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2012</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>20</p>
          </td>
          <td align="left">
            <p>Topper</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>2013</p>
          </td>
          <td align="left">
            <p>21</p>
          </td>
          <td align="left">
            <p>20</p>
          </td>
          <td align="left">
            <p>19</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
      </tbody>
    </Table>
    <h2>
      <strong>Publications by faculty ( Last five years)</strong>
    </h2>
    <blockquote></blockquote>
    <h2>
      <strong>Details of Publication (Last five years)</strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>
              <strong>Sl. No.</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Name of Teachers</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>No. of Papers Published</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Scheduled Attached (Page No.)</strong>
            </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>1</p>
          </td>
          <td align="left">
            <p>Dr. C.K. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>29</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p>Dr. Kaushalendra Kr.</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>Dr. M.K. Ghosh</p>
          </td>
          <td align="left">
            <p>0</p>
          </td>
          <td align="left">
            <p>24</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p>Dr. Naresh Kr.</p>
          </td>
          <td align="left">
            <p>9</p>
          </td>
          <td align="left">
            <p>18</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>Dr. Arun Kr. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>14</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>6</p>
          </td>
          <td align="left">
            <p>Dr. Ashok Kr. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>9</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>7</p>
          </td>
          <td align="left">
            <p>Dr. Anil Kr.</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>5</p>
          </td>
        </tr>
      </tbody>
    </Table>
    <h2>
      <strong>
        Awards &amp; Recognition received by faculty (Last Five Years)
      </strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>
              <strong>Sl. No.</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Name of Teachers</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Awarded &amp; Recognition</strong>
            </p>
          </td>
          <td align="left">
            <p>Scheduled Attached (Page No.)</p>
          </td>
          <td align="left">
            <p>Remarks</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>1</p>
          </td>
          <td align="left">
            <p>Dr. C.K. Yadav</p>
          </td>
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p>28</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p>Dr. Kaushalendra Kr.</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>Dr. M.K. Ghosh</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>23</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p>Dr. Naresh Kr.</p>
          </td>
          <td align="left">
            <p>6</p>
          </td>
          <td align="left">
            <p>17</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>Dr. Arun Kr. Yadav</p>
          </td>
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>13</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>6</p>
          </td>
          <td align="left">
            <p>Dr. Ashok Kr. Yadav</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
          <td align="left">
            <p>8</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>7</p>
          </td>
          <td align="left">
            <p>Dr. Anil Kr.</p>
          </td>
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
      </tbody>
    </Table>
    <h2>
      <strong>
        Faculty who have attended National &amp; International Seminar (Last
        five Years)
      </strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <col width="20%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>
              <strong>Sl. No.</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Name of Teachers</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>No. of Seminar Attended/ paper presented</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>Scheduled Attached </strong>
            </p>
          </td>
          <td align="left">
            <p>
              <br />
            </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>1</p>
          </td>
          <td align="left">
            <p>Dr. C.K. Yadav</p>
          </td>
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>28</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p>Dr. Kaushalendra Kr.</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>Dr. M.K. Ghosh</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>22</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p>Dr. Naresh Kr.</p>
          </td>
          <td align="left">
            <p>13</p>
          </td>
          <td align="left">
            <p>17</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>Dr. Arun Kr. Yadav</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
          <td align="left">
            <p>13</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>6</p>
          </td>
          <td align="left">
            <p>Dr. Ashok Kr. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
          <td align="left">
            <p>8</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>7</p>
          </td>
          <td align="left">
            <p>Dr. Anil Kr.</p>
          </td>
          <td align="left">
            <p>5</p>
          </td>
          <td align="left">
            <p>4</p>
          </td>
          <td align="left">
            <p> </p>
          </td>
        </tr>
      </tbody>
    </Table>
    <h2>
      <strong>Number of Ph. D Thesis guided during the Last 5 Years.</strong>
    </h2>
    <blockquote></blockquote>
    <Table striped bordered hover responsive>
      <col width="50%" />
      <col width="50%" />
      <tbody>
        <tr class="odd">
          <td align="left">
            <p>
              <strong>Name of Teachers</strong>
            </p>
          </td>
          <td align="left">
            <p>
              <strong>No. of Ph. D Thesis guided / award submitted</strong>
            </p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>Dr. C.K. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>Dr. Kaushalendra Kr.</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>Dr. M.K. Ghosh</p>
          </td>
          <td align="left">
            <p>0</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>Dr. Naresh Kr.</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>Dr. Arun Kr. Yadav</p>
          </td>
          <td align="left">
            <p>2</p>
          </td>
        </tr>
        <tr class="odd">
          <td align="left">
            <p>Dr. Ashok Kr. Yadav</p>
          </td>
          <td align="left">
            <p>3</p>
          </td>
        </tr>
        <tr class="even">
          <td align="left">
            <p>Dr. Anil Kr.</p>
          </td>
          <td align="left">
            <p>1</p>
          </td>
        </tr>
      </tbody>
    </Table>
  </Layout>
)

export default Statistics
